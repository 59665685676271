import React from 'react';
import GoogleSignIn from './GoogleSignIn'; 
import companyLogo from './logo/NF_Logo.png';

const Landing: React.FC = () => {
  return (
    <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh', 
        textAlign: 'center', 
        padding: '20px'
    }}>
      <img src={companyLogo} alt="Company Logo" style={{ 
          maxWidth: '500px', 
          marginBottom: '20px' 
      }} />
      <h3>Authenticate to continue</h3>
      <GoogleSignIn />
    </div>
  );
};

export default Landing;