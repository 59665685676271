// UserHome.tsx
import React from 'react';
import './UserHome.css';
import demoCategories from './demosConfig'; 

export interface AuthUser {
  username: string;
}

interface UserHomeProps {
  user: AuthUser | null;
}

const UserHome: React.FC<UserHomeProps> = ({ user }) => {
  return (
    <div className="userHome">
      <header>
        <h1>NeuraFlash Solution Engineering Demos</h1>
      </header>

      <main>
        {demoCategories.map((category, index) => (
          <section key={index} className="category-section">
            <h2>{category.category}</h2>
            <div className="demo-container">
              {category.demos.map((demo, demoIndex) => (
                <div key={demoIndex} className="demo">
                  <div className="logo-box">
                    <img src={demo.logo} alt={`${demo.name} Logo`} className="demo-logo" />
                  </div>
                  <div className="demo-box">
                    <h3>{demo.name}</h3>
                    <a href={demo.url} target="_blank" rel="noopener noreferrer">Go to Demo</a>
                  </div>
                </div>
              ))}
            </div>
          </section>
        ))}
      </main>

      <footer>
        <p>&copy; 2023 NeuraFlash</p>
      </footer>
    </div>
  );
};

export default UserHome;
