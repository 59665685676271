// src/components/demosConfig.ts
import jerseyMikesLogo from './logo/Jersey_Mike_logo.png';
import usaaLogo from './logo/USAA_logo.png';
//more logos can be imported here

export interface Demo {
  name: string;
  url: string;
  logo: string;
}

export interface DemoCategory {
  category: string;
  demos: Demo[];
}

const demoCategories: DemoCategory[] = [
  {
    category: "Gen AI Demos",
    demos: [
      {
        name: "Jersey Mike's GenAI Demo",
        url: 'http://sandwitchorder-genai-demo.s3-website-us-west-2.amazonaws.com/',
        logo: jerseyMikesLogo,
      },
      {
        name: "USAA GenAI Demo",
        url: 'http://columbia-chat-dem0-nf.s3-website-us-east-1.amazonaws.com/',
        logo: usaaLogo,
      },
      // More demos can be added here
    ],
  }
  // More categories can be added here
];

export default demoCategories;
