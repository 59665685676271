import React from 'react';
import { signInWithRedirect } from '@aws-amplify/auth';

const GoogleSignIn: React.FC = () => {
  return (
    <button onClick={() => signInWithRedirect({ provider: 'Google' })}>
      Sign in with Google
    </button>
  );
};

export default GoogleSignIn;
