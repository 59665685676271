import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import config from './aws-exports'; 
import App from './App';
import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider } from '@aws-amplify/ui-react';

// Check if the hostname is localhost is handled within the config object itself
const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    
    redirectSignIn: config.oauth.redirectSignIn,
    redirectSignOut: config.oauth.redirectSignOut,
  }
};

Amplify.configure(updatedAwsConfig);

ReactDOM.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);
