import React, { useEffect, useState } from 'react';
import { getCurrentUser } from '@aws-amplify/auth';
import LandingPage from './components/Landing'; // Your sign-in component
import UserHome from './components/UserHome'; // Your component with demo URLs

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkCurrentUser();
  }, []);

  // Check if a user is currently authenticated when the app loads
  async function checkCurrentUser() {
    try {
      const userData = await getCurrentUser();
      if (userData) {
        setUser(userData);
      }
      setLoading(false);
    } catch (error) {
      console.log("User not signed in", error);
      setUser(null);
      setLoading(false);
    }
  }

  // Show a loading message while checking the user's sign-in state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Conditionally render UserHome if a user is signed in, otherwise show the landing page
  return (
    <div>
      {user ? <UserHome user={user} /> : <LandingPage />}
    </div>
  );
}

export default App;
